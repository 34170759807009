<template>
  <div class="warehousing-record">
    <PageHeader title="预收货记录" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          v-model="visible"
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
        >
          <div class="flex column">
            <div class="cursor" @click="selectType(1)">按预收货查询</div>
            <div class="p-tb-10 cursor" @click="selectType(2)">按物料查询</div>
          </div>
          <div slot="reference" class="out white bg p-lr-10 p-tb-5 min-border-right flex a-center f12 m-right-10 pointer" @click="visible = !visible">
            <div>{{ formSearch.ReportType === 1 ? '按预收货查询' : '按物料查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <div class="flex m-left-10 SearchBox_30  m-right-20" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="可通过预入库单号、订单号、物料号、物料名称、供应商名称搜索"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="search">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div
        v-if="formSearch.ReportType === 2"
        slot="reference"
        class="out p-lr-10 min-border flex a-center m-right-10 pointer ModelBttton-white-30"
        @click="exportS "
      >
        <i class="el-icon-upload2 f15 p-right-10" /> 导出
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'DateCreate','BeginDate','EndDate')" />
    <div v-if="formSearch.ReportType === 1" class="p-lr-20">
      <!-- 按预入库单查询 -->
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        tooltip-effect="dark"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        show-header
        height="calc(100vh - 330px)"
        row-key="IDX"
        border
        :fit="true"
      >
        <!--        <el-table-column type="selection" width="40" />-->
        <el-table-column prop="ASNID" label="预入库单号" show-overflow-tooltip />
        <el-table-column prop="PurchaseOrderID" label="采购单号" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="订单时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column prop="OASNIWriteTime" label="预入库单创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.OASNIWriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="ExpressNo" label="物流单号" show-overflow-tooltip />
        <el-table-column prop="InStockState" label="入库状态" show-overflow-tooltip />
      </el-table>

    </div>
    <div v-if="formSearch.ReportType === 2" class="p-lr-20">
      <!-- 按物料查询 -->
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 330px)"
        row-key="IDX"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column :key="Math.random()*500" fixed="left" type="selection" width="40" />
        <el-table-column prop="ASNID" label="预入库单号" show-overflow-tooltip width="150px" />
        <el-table-column prop="PurchaseOrderID" label="采购单号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="LotNum" label="批号/序列号" show-overflow-tooltip />
        <el-table-column prop="LotNo" label="入库批次号" show-overflow-tooltip />
        <el-table-column prop="ProductDateShow" label="生产日期" show-overflow-tooltip />
        <el-table-column prop="ExpiredDate" label="效期" show-overflow-tooltip />
        <el-table-column prop="Num" label="预收货数量" show-overflow-tooltip />
        <el-table-column prop="InStockNum" label="入库数量" show-overflow-tooltip />
        <el-table-column prop="ExternalPackingShow" label="外包装" show-overflow-tooltip />
        <el-table-column prop="Temperature" label="到货温度" show-overflow-tooltip />
        <el-table-column prop="DINum" label="DI码" show-overflow-tooltip />

        <el-table-column prop="InStockState" label="入库状态" show-overflow-tooltip />

        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="OASNIWriteTime" label="预入库单号生产时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.OASNIWriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="ExpressNo" label="物流单号" show-overflow-tooltip />
        <el-table-column prop="UpdaterNickName" label="入库操作人" show-overflow-tooltip />
        <el-table-column prop="Location" label="入库仓库" show-overflow-tooltip />
        <el-table-column prop="SellCompanyName" label="供应商" show-overflow-tooltip />
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        :ref="paginationName"
        :total="tableTotalSize"
        :pager-count="pagerCount"
        :page-size="tablePageSize"
        :background="tableBackground"
        :current-page.sync="currentPage"
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange($event)"
      />
    </div>

    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/warehousing-record'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
