import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
const Index = {
  name: 'warehousingRecord',
  mixins: [tableMixin, root],
  components: {
    PageHeader,
    Adsearch
  },
  data() {
    return {
      root_name: 'yshjl',
      root_msg: '预收货记录',
      isShow: false,
      visible: false,
      formSearch: {
        ReportType: 1,
        KeyWords: '',
        ASNID: '',
        PurchaseOrderID: '',
        InvCode: '',
        BrandName: '',
        InvName: '',
        MaterialTypeName: '',
        InvShotName: '',
        SellCompanyName: '',
        CertificateName: '',
        DINum: '',
        ManuPartNum: '',
        OrigFactory: '',
        BeginDate: '',
        EndDate: '',
        CompanyID: '',
        InStockOrderID: '',
        OperationNickName: '',
        ExportType: '',
        SelectIDList: [],
        ExpressNo: '',
        DateCreate: [],
        PageIndex: 1,
        PageSize: 20
      },
      adSearchForm: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: '创建日期', value: 'DateCreate', date: true },
        { name: '物料名称', value: 'InvName' },
        { name: '物料编号', value: 'InvCode' },
        { name: '物流单号', value: 'ExpressNo' }
      ],
      selects: []
    }
  },
  watch: {
    selects(a) {
      this.formSearch.SelectIDList = []
      if (a.length <= 0) return this.formSearch.SelectIDList = []
      a.map(item => {
        this.formSearch.SelectIDList.push(item.IDX)
      })
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    search() {
      this.isShow = true
    },
    searchAd() {
      console.log(this.formSearch)
      if (this.formSearch.DateCreate.length > 0) {
        this.formSearch.BeginDate = this.formSearch.DateCreate[0]
        this.formSearch.EndDate = this.formSearch.DateCreate[1]
      }
      this.currentPage = 1
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.isShow = false
    },
    selectType(num) {
      this.formSearch.ReportType = num
      this.visible = false
      this.currentPage = 1
      this.getTableDataAsync()
    },
    exportS() {
      this.$api.ExportQueryOrderASNReceiveLogReportList(this.formSearch).then(res => {
        if (res.RetCode === '0') {
          return this.$minCommon.downloadFile(res.Message)
        }
        return this.$message.error('错误：', res.Msg)
      })
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.QueryOrderASNReceiveLogReportList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      // console.log(response)
      this.tableLoading = false
      return response
    }
  }
}
export default Index
